import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import loadable from '@loadable/component';

import './index.css';
import './dark_mode.css';

const Home = loadable(() => import('./home/home.js'));
const EZTicTacToe = loadable(() => import('./projects/ez-tic-tac-toe/ez-tic-tac-toe.js'));

const root = ReactDOM.createRoot(document.getElementById('root'));
document.title = "Matt Geimer | CS @ UIUC"
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={ <Home/> } />
      <Route path="projects">
        <Route path="ez-tic-tac-toe" element={ <EZTicTacToe /> }/>
      </Route>
    </Routes>
  </BrowserRouter>
);